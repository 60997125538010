<template>
  <div class="wrapper">
    <b-container>
      <b-row style="margin-top:80px">
        <b-col>
          <p class="TextBlack32">{{ $t("Telegram_Bot") }}</p>
        </b-col>
      </b-row>
      <b-row>
          <b-col sm="12" lg="2">
              <p class="TextBlack17">{{$t('View_Bot')}}</p>
          </b-col>
          <b-col sm="12" lg="2">
              <!-- <b-button @click="Create" class="buttonTaklif mt-1"> -->
              <!-- <p class=" m-1 "> -->
                  <a href="https://t.me/XTV_info_bot">
                   <img style="cursor:pointer;" src="@/assets/bot.png" width="50px" alt="">
                  </a>
                
              <!-- </p> -->
            <!-- </b-button> -->
          </b-col>
      </b-row>
      <b-row  class="mb-4">
        <b-col sm="12" md="12" lg="12" v-for="(item2, index) in Info.Files" :key="index">
          <iframe style="width:100%;min-height:500px" allowfullscreen :src="
                axios.defaults.baseURL +
                  `Instruction/GetDownloadFile?id=${Info.id}&fileid=${item2.id}`
              " frameborder="0"></iframe>
          <!-- <b-embed
            type="iframe"
            aspect="16by9"
           :src="
                axios.defaults.baseURL +
                  `Instruction/GetDownloadFile?id=${Info.id}&fileid=${item2.id}`"
            allowfullscreen
          ></b-embed> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col
        class="mb-2"
          sm="12"
          md="4"
          lg="4"
          v-for="(item, index) in UzasboList"
          :key="index"
        >
          <b-card style="min-height:160px;cursor:pointer" class="taklifCard" @click="GetFunction(item)">
            <b-row>
                <b-col class="text-center">
                    <img v-if="item.filextention =='.mp4' " width="20%" src="@/assets/img/youtube.png" alt="">
                    <img v-if="item.filextention =='.docx' " width="20%" src="@/assets/img/doc.png" alt="">
                    <img v-if="item.filextention =='.pdf' || item.filextention =='.ppt' " width="20%" src="@/assets/img/pdf.png" alt="">
                    <img v-if="item.filextention =='.png' " width="20%" src="@/assets/img/image.png" alt="">
                </b-col>
            </b-row>
            <p class="mt-1 text-center mb-0">{{ item.headertext }}</p>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import InstructionService from "@/services/Instruction.service";
import axios from "axios"
export default {
  data() {
    return {
        axios,
      Loading: false,
      filter: {
        Typeid: 3,
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 1000,
        lang: "",
      },
      UzasboList: [],
      Info: [],
    };
  },
  created() {
    this.filter.lang = localStorage.getItem("locale") || "uz_cyrl";
    InstructionService.GetList(
      this.filter.Typeid,
      this.filter.Search,
      this.filter.SortColumn,
      this.filter.OrderType,
      this.filter.PageNumber,
      this.filter.PageLimit,
      this.filter.lang
    ).then((res) => {
      this.Loading = true;
      this.UzasboList = res.data.rows;
    });
  },
  methods: {
    GetFunction(item) {
      InstructionService.Get(item.id).then((res) => {
        this.Info = res.data
      });
    },
  },
};
</script>
<style scoped></style>
