import ApiService from "./api.service";
const InstructionService = {
  GetList(Typeid, Search, SortColumn, OrderType, PageNumber, PageLimit, lang) {
    return ApiService.get(
      `/Instruction/GetList?Typeid=${Typeid}&Search=${Search}&SortColumn=${SortColumn}&OrderType=${OrderType}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&lang=${lang}`
    );
  },
  Update(data) {
    return ApiService.formData(`/Instruction/Update`, data);
  },
  Get(id) {
    return ApiService.get(`/Instruction/Get?id=${id}`);
  },
  Delete(id) {
    return ApiService.delete(`/Instruction/Delete?id=${id}`);
  },
  GetDownloadFile(id, fileid) {
    return ApiService.get(
      `/Instruction/GetDownloadFile?id=${id}&fileid=${fileid}`
    );
  },
  DeleteFile(id) {
    return ApiService.delete(`/Instruction/DeleteFile?id=${id}`);
  },
};
export default InstructionService;
